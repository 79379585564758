<template>
  <div class="table_wrapper">
    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="c1"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1"
      :search="search"
      :items-per-page="perPage"
      :options.sync="options"
      :server-items-length="totalDesserts"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Categories</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left v-if="activeTab == 0 || activeTab == 2">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="warning"
              :to="'categories/edit/' + item.item.id"
              class="mx-1"
              fab
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>

        <v-tooltip right v-if="activeTab == 0 || activeTab == 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="rejected(item.item)"
              color="error"
              class="mx-1"
              fab
              disabled
              small
              elevation="2"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>
                mdi-close
              </v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Categories",
  data() {
    return {
      options: {},
      search: "",
      activeTab: 0,
      loading: true,
      token: localStorage.getItem("authToken"),
      headers: [
        { text: "ID", value: "c1", sortable: false },
        { text: "Title", value: "c2", sortable: false },
        { text: "Description", value: "c3", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      desserts: [],
      totalDesserts: 0,
      page: 1,
      perPage: 33,
      itemsData: [],
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi(0);
      },
      deep: true,
    },
  },
  methods: {
    parseLink: function(link) {
      return link.substr(0, 50) + "...";
    },
    rejected(item) {
      console.log(item);
    },
    getDataFromApi(a = null) {
      if (a) {
        this.activeTab = a;
      }
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = [];
        data.itemsR.forEach((item) => {
          const key = {
            c1: item.id,
            c2: item.title,
            c3: item.description,
            actions: null,
            item: item,
          };
          this.desserts.push(key);
        });

        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    fakeApiCall() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        this.page = page;
        this.perPage = itemsPerPage == -1 ? this.totalDesserts : itemsPerPage;

        this.getDesserts().then((res) => {
          this.itemsData = res.data;

          const total = res.total;

          let itemsR = this.itemsData;

          setTimeout(() => {
            resolve({
              itemsR,
              total,
            });
          }, 1000);
        });
      });
    },
    getDesserts() {
      return axios
        .get(process.env.VUE_APP_API_URL + "category?api_token=" + this.token)
        .then(function(response) {
          return response.data;
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>
